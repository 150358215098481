import { AlertSubTypesEnum, AlertTypesEnum, EntityTypes } from '../../utilities/Constants';
import { useEffect, useState } from 'react';

import Common from '../../stores/Common';
import { ICollabReportStatusModel } from '../../interfaces/ICollabReport';
import { IUpdateAlertUserModel } from '../../interfaces/Report/IReport';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LoadingButton } from '../../shared/buttons/LoadingButton';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCloseAlertMutation } from './useCloseAlertMutation';
import { useGetCollaborationReportStatus } from './useGetCollaborationReportStatus';

interface ICollabActHistoryProps {
    alertGuid: string;
    reportGuid: string;
    reportStatus?: number;
}

export function CollabActHistory(props: ICollabActHistoryProps) {
    const auth = useAuthContext();
    const userStatuses = useGetCollaborationReportStatus(props.reportGuid);
    const closeChatActMutation = useCloseAlertMutation();

    const [validation, setValidation] = useState<IValidation>({});

    const handleCollablActClick = async () => {
        if (!props.alertGuid) {
            setValidation((prev) => ({ ...prev, act: ['Failed to close Collab Act'] }));
        }

        const model = {
            alertGuid: props.alertGuid,
            alertType: AlertTypesEnum.Act,
            alertSubType: AlertSubTypesEnum.Collab,
            parentEntityGuid: props.reportGuid,
            parentEntityType: EntityTypes.Report,
        } as IUpdateAlertUserModel;

        await closeChatActMutation
            .mutateAsync(model)
            .then(() => {
                userStatuses.refetch();
            })
            .catch(() => {
                setValidation((prev) => ({ ...prev, closeAct: ['Failed to close Collab Act'] }));
            });
    };

    useEffect(() => {
        userStatuses.refetch();
    }, []);

    if (userStatuses.isLoading) {
        return <div>Loading collaborators...</div>;
    }

    const collaboratorFilter = (x: ICollabReportStatusModel) => {
        return x.subTypeId === AlertSubTypesEnum.Collab;
    };
    const reviewFilter = (x: ICollabReportStatusModel) => {
        return x.subTypeId === AlertSubTypesEnum.Review;
    };

    const sortByName = (a: ICollabReportStatusModel, b: ICollabReportStatusModel) =>
        (a.user.profile?.firstName ?? '') < (b.user.profile?.firstName ?? '') ? -1 : 1;

    const sortedCollaborators =
        userStatuses.data?.filter(collaboratorFilter).sort(sortByName) ?? [];

    const sortedReaders = userStatuses.data?.filter(reviewFilter).sort(sortByName) ?? [];

    return (
        <div style={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}>
            {(sortedCollaborators?.length ?? 0) > 0 && (
                <div className="row">
                    <div style={{ paddingRight: 0 }} className="mt-2">
                        <label className="control-label">Collab Act Status</label>
                    </div>
                    <div className="mt-2">
                        <table>
                            <tbody>
                                {sortedCollaborators
                                    .sort((a, b) =>
                                        (a.user.profile?.firstName ?? '') <
                                        (b.user.profile?.firstName ?? '')
                                            ? -1
                                            : 1
                                    )
                                    .map((collaborator) => (
                                        <ActStatusRow
                                            collaborator={collaborator}
                                            currentUser={auth.user}
                                            onActCloseClick={handleCollablActClick}
                                            isLoadingStatus={closeChatActMutation.isLoading}
                                            key={
                                                collaborator.user.guid + collaborator.dateCompleted
                                            }
                                        />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-danger mt-1">{validation.closeCollabAct}</div>
                </div>
            )}
            {(sortedReaders?.length ?? 0) > 0 && (
                <div className="row">
                    <div style={{ paddingRight: 0 }} className="mt-2">
                        <label className="control-label">Review Act Status</label>
                    </div>
                    <div className="mt-2">
                        <table>
                            <tbody>
                                {sortedReaders.map((collaborator) => (
                                    <ActStatusRow
                                        collaborator={collaborator}
                                        currentUser={auth.user}
                                        onActCloseClick={handleCollablActClick}
                                        isLoadingStatus={closeChatActMutation.isLoading}
                                        key={collaborator.user.guid + collaborator.dateCompleted}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="text-danger mt-1">{validation.closeReviewAct}</div>
                </div>
            )}
        </div>
    );
}

interface IActStatusProps {
    collaborator: any;
    currentUser: IUserModel;
    isLoadingStatus?: boolean;
    onActCloseClick: () => void;
}

function ActStatusRow(props: IActStatusProps) {
    if (!props.collaborator.user.profile) return null;

    const { firstName, lastName } = props.collaborator.user.profile;

    return (
        <tr key={props.collaborator.user.guid} style={{ verticalAlign: 'middle' }}>
            <td style={{ paddingRight: '1em', height: 33 }}>
                {firstName} {lastName}
            </td>
            <td>
                {props.collaborator.completed ? (
                    <span style={{ paddingLeft: 11 }}>
                        Closed on {Common.dateTimeFormatToLocal(props.collaborator.dateCompleted)}
                    </span>
                ) : (
                    <>
                        {props.currentUser.guid === props.collaborator.user.guid ? (
                            <LoadingButton
                                loading={props.isLoadingStatus}
                                text="Collab Act"
                                icon="fal fa-check"
                                onClick={props.onActCloseClick}
                                buttonCssClass="btn btn-no-bg font-orange"
                            />
                        ) : (
                            <span className="text-gray" style={{ paddingLeft: 11 }}>
                                Pending
                            </span>
                        )}
                    </>
                )}
            </td>
        </tr>
    );
}
