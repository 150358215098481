import {
    DataScopesEnum,
    EntityTypes,
    ExportOptions,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    MyDocumentsTypesEnum,
    StrategyTypes,
    UserRightsEnum,
} from '../../utilities/Constants';
import { IExpertUpdateModel, IGeneralOpinion } from '../../interfaces/IExpert';
import { useGetExpert, useGetExpertKey } from './useGetExpert';
import { useLocation, useNavigate } from 'react-router-dom';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ExpertDetailForm } from '../../components/expert/ExpertDetailForm';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import { IDocumentModel } from '../../interfaces/ICase';
import { INoteModel } from '../../interfaces/INote';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteListWrapper } from '../../components/case/static-sections/NoteListWrapper';
import Sort from '../../stores/Sort';
import UIHelper from '../../utilities/UIHelper';
import { WorkProductWrapper } from '../../components/work-product/WorkProductWrapper';
import { useExpertUpdateMutation } from './useExpertUpdateMutation';
import { useNoteCategoryTypes } from '../../shared/react-query-hooks/useNoteCategoryTypes';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { useState } from 'react';

const _apiClient = new ApiClient();
export interface IExpertDetailProps {
    user: IUserModel;
}

export function ExpertDetail(props: IExpertDetailProps) {
    const { id: expertGuid } = useParams<{ id: string }>();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { state: locationState } = useLocation();

    const [formData, setFormData] = useState<IExpertUpdateModel>();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [openNoteEditor, setOpenNoteEditor] = useState<boolean>(false);
    const [forceNoteEditorInReadOnlyMode, setForceNoteEditorInReadOnlyMode] =
        useState<boolean>(false);
    const [currentNote, setCurrentNote] = useState<INoteModel>({
        status: { id: NoteStatusTypes.Open },
        purpose: { id: NotePurposeTypesEnum.Info },
    });
    const [validation, setValidation] = useState<IValidation>({});
    const [showDuplicateExpertMsg, setShowDuplicateExpertMsg] = useState<boolean>(false);

    const { isLoading, data: expert, error: apiError } = useGetExpert(expertGuid);
    const categoryNoteTypes = useNoteCategoryTypes(EntityTypes.AssertionDescriptorValueStrategy); // todo: make this filter by entity type

    const hasEditPermission = Authorization.userHasRight(
        UserRightsEnum.UpdateCaseExpert,
        props.user
    );

    const hasViewExpertStrategyNotePermission = Authorization.userHasRight(
        UserRightsEnum.ViewExpertStrategyNote,
        props.user
    );

    const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(props.user);
    const userAuthorizedToEditNote = NoteHelper.isUserAuthorizedToEditNote(
        currentNote,
        props.user,
        Authorization.userHasRight(UserRightsEnum.ViewExpertStrategyNote, props.user),
        Authorization.userHasRight(UserRightsEnum.UpdateExpertStrategyNote, props.user),
        Authorization.userHasRight(UserRightsEnum.AddExpertStrategyNote, props.user)
    );

    const updateMutation = useExpertUpdateMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        const validation: IValidation = {};

        const currentNote = expert?.notes?.find((x) => x.guid == noteGuidToEdit);

        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            setValidation(validation);
            return;
        }
        setCurrentNote(JSON.parse(JSON.stringify(currentNote)));
        setOpenNoteEditor(true);
        setForceNoteEditorInReadOnlyMode(readOnly ?? false);
    };

    const handleClickAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.StrategyNote },
            status: { id: NoteStatusTypes.Open },
            parent: {
                entityType: { id: EntityTypes.Expert },
                entityGuid: expert?.guid,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        setCurrentNote(currentNote);
        setOpenNoteEditor(true);
        setForceNoteEditorInReadOnlyMode(false);
    };

    const handleCancelNoteEditor = () => {
        setCurrentNote({
            status: { id: NoteStatusTypes.Open },
            purpose: { id: NotePurposeTypesEnum.Info },
        });
        setOpenNoteEditor(false);
    };

    const handleSaveClick = async () => {
        if (!expertGuid) return;

        const validation: IValidation = {};
        let hasErrors = false;

        if (!formData?.lastName) {
            validation.lastName = ['Required'];
            hasErrors = true;
        }

        if (!formData?.firstName) {
            validation.firstName = ['Required'];
            hasErrors = true;
        }

        if (!formData?.expertTypeId) {
            validation.type = ['Required'];
            hasErrors = true;
        }

        if (!formData?.disciplines?.length ?? 0 === 0) {
            validation.discipline = ['Required'];
            hasErrors = true;
        }

        setValidation(validation);

        if (hasErrors) {
            return;
        }

        const expertLastName = formData?.lastName ?? '';
        const response = await _apiClient.isExpertNameExists(expertLastName, expertGuid);
        if (response.errorMessage) {
            if (response.errors !== undefined && response.errors[0] !== undefined) {
                setError(`Failed to update Expert : ${response.errors[0].message}`);
            }
            return;
        }

        if (response?.httpResponse.ok) {
            //if expert Last Name is duplicate then show the warning message.
            if (response.payload === true) {
                setShowDuplicateExpertMsg(true);
            } else {
                //other wise update Expert automatically.
                updateExpert();
            }
        }
    };

    const updateExpertWithDuplicateName = async () => {
        await updateExpert();
    };

    const updateExpert = async () => {
        const mappedFormData: IExpertUpdateModel = {
            ...formData,
            guid: expertGuid!,
            firstName: formData?.firstName ?? '',
            lastName: formData?.lastName ?? '',
            credentials: formData?.credentials ?? '',
            expertTypeId: formData?.expertTypeId ?? 0,
            generalOpinions: formData?.generalOpinions?.map(
                (go, idx) =>
                    ({
                        topic: {
                            ...go.topic,
                            correlationKeyId: idx,
                        },
                        opinion: {
                            ...go.opinion,
                            correlationKeyId: idx,
                        },
                    } as IGeneralOpinion)
            ),
        };

        updateMutation
            .mutateAsync(mappedFormData!)
            .then(({ payload }) => {
                if (payload) {
                    queryClient.setQueryData([useGetExpertKey, expert?.guid], {
                        ...formData, // this is a patch because the response isn't sending back disciplines. Only the payload.data should be needed here.
                        ...payload.data,
                    });
                }

                setIsEditMode(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const handleCancelClick = () => {
        setIsEditMode(false);
    };

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleNoteSaveComplete = (noteItem: INoteModel) => {
        const expertCopy = { ...expert };
        const notesCopy = [...(expertCopy?.notes ?? [])];

        const foundIdx = notesCopy.findIndex((note) => note.guid === noteItem.guid) ?? -1;

        if (foundIdx > -1) {
            notesCopy[foundIdx] = noteItem;
        } else {
            notesCopy.push(noteItem);
        }

        notesCopy.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));

        expertCopy.notes = notesCopy;

        queryClient.setQueryData([useGetExpertKey, expert?.guid], expertCopy);

        setOpenNoteEditor(false);
        setCurrentNote({
            status: { id: NoteStatusTypes.Open },
            purpose: { id: NotePurposeTypesEnum.Info },
        });
    };

    const handleDocumentSaveComplete = (document: IDocumentModel) => {
        const expertCopy = { ...expert };
        const docsCopy = [...(expertCopy?.documents ?? [])];

        const foundIdx = docsCopy.findIndex((doc) => doc.guid === document.guid) ?? -1;

        if (foundIdx > -1) {
            docsCopy[foundIdx] = document;
        } else {
            docsCopy.push(document);
        }

        docsCopy.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        expertCopy.documents = docsCopy;

        queryClient.setQueryData([useGetExpertKey, expert?.guid], expertCopy);
        navigate({}, { replace: true }); // remove location state
    };

    const handleDocumentDeleteComplete = (documentGuid: string) => {
        const expertCopy = { ...expert };
        const docsCopy = [...(expertCopy?.documents ?? [])];

        const filteredDocsCopy = docsCopy.filter((doc) => doc.guid !== documentGuid);

        filteredDocsCopy.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));

        expertCopy.documents = filteredDocsCopy;

        queryClient.setQueryData([useGetExpertKey, expert?.guid], expertCopy);
    };

    const showUpload = !!locationState?.showUpload;

    if (isLoading || updateMutation.isLoading) {
        return <Loader />;
    }

    if (apiError instanceof Error) {
        return (
            <div className="row mb-1 me-2">
                <span className="text-danger">{apiError.message}</span>
            </div>
        );
    }

    if (openNoteEditor) {
        return (
            <NoteEditor
                forceNoteEditorInReadOnlyMode={forceNoteEditorInReadOnlyMode}
                authorizedToEdit={userAuthorizedToEditNote}
                strategyTypeId={StrategyTypes.Experts.Value}
                originEntityType={EntityTypes.Expert}
                originEntityName={expert?.firstName + ' ' + expert?.lastName}
                originEntityKey={expert?.guid}
                user={props.user}
                noteCategoryTypes={categoryNoteTypes.data ?? []}
                currentNote={currentNote}
                handleSaveComplete={handleNoteSaveComplete}
                handleCancel={handleCancelNoteEditor}
            />
        );
    }

    return (
        <>
            <div className="row mb-3">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <h1 className="mb-0">Expert Profile - {UIHelper.formatExpertName(expert!)}</h1>
                    <div>
                        {hasEditPermission && (
                            <Buttons
                                expertGuid={expertGuid ?? ''}
                                isEditMode={isEditMode}
                                onSaveClick={handleSaveClick}
                                onCancelClick={handleCancelClick}
                                onEditClick={handleEditClick}
                                onSetValidation={(validation) =>
                                    setValidation((prev) => ({ ...prev, ...validation }))
                                }
                            />
                        )}
                        {hasViewExpertStrategyNotePermission && !isEditMode && (
                            <a className="btn btn-no-bg float-end text-gray" href="#Notes">
                                <i className="fal fa-memo" />
                                &nbsp;Strategy Notes
                            </a>
                        )}
                    </div>
                </div>
            </div>
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            {validation?.model && <span className="text-danger">{validation.model}</span>}
            <ExpertDetailForm
                user={props.user}
                expert={expert}
                onFormDataUpdate={setFormData}
                isEditMode={isEditMode}
                isCreateMode={false}
                validation={validation}
            />
            <CheckmateDialog
                isShowingModal={showDuplicateExpertMsg}
                body="An expert with the same last name already exists. Do you want to continue?"
                handleClose={() => {
                    setShowDuplicateExpertMsg(false);
                }}
                handleConfirm={() => {
                    updateExpertWithDuplicateName();
                    setShowDuplicateExpertMsg(false);
                }}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
            {!isEditMode && (
                <>
                    <div className="row" id="AttachmentsDiv">
                        <WorkProductWrapper
                            uploadOnly={true}
                            user={props.user}
                            entityGuid={expertGuid!}
                            entityTypeId={EntityTypes.Expert}
                            title="Attachments"
                            documents={expert?.documents}
                            parentEntityJson={JSON.stringify(expert)}
                            parentEntityRedirectUrl={LocalRoutes.ExpertDetail.replace(
                                ':id',
                                expertGuid!
                            )}
                            authorizedToEdit={Authorization.userHasRight(
                                UserRightsEnum.UpdateExpert,
                                props.user
                            )}
                            displayUploadWorkProductDialog={showUpload}
                            onSaveComplete={handleDocumentSaveComplete}
                            onDeleteComplete={handleDocumentDeleteComplete}
                        />
                    </div>
                    <div className="row" id="Notes" style={{ marginTop: '15px' }}>
                        <NoteListWrapper
                            title="Strategy Notes"
                            titleClassName="text-gray font-size-sm"
                            entityType={EntityTypes.Expert}
                            user={props.user}
                            notes={expert?.notes ?? []}
                            onViewEditNote={handleViewEditNote}
                            onAddNote={handleClickAddNote}
                            allowView={Authorization.userHasRight(
                                UserRightsEnum.ViewExpertStrategyNote,
                                props.user
                            )}
                            allowEdit={
                                Authorization.userHasRight(
                                    UserRightsEnum.UpdateExpertStrategyNote,
                                    props.user
                                ) &&
                                (loggedInUserDataScopeId !== DataScopesEnum.LocalBasic.Value ||
                                    currentNote.allowLocalEdit == true)
                            }
                            allowDelete={Authorization.userHasRight(
                                UserRightsEnum.DeleteExpertStrategyNote,
                                props.user
                            )}
                            allowAddNew={Authorization.userHasRight(
                                UserRightsEnum.AddExpertStrategyNote,
                                props.user
                            )}
                        />
                    </div>
                </>
            )}
        </>
    );
}

interface IButtonsProps {
    expertGuid: string;
    isEditMode: boolean;
    onEditClick: () => void;
    onCancelClick: () => void;
    onSaveClick: () => void;
    onSetValidation: (validation: IValidation) => void;
}

function Buttons({
    expertGuid,
    isEditMode,
    onEditClick,
    onCancelClick,
    onSaveClick,
    onSetValidation,
}: IButtonsProps) {
    const handleExportError = () => {
        onSetValidation({ model: [DisplayMessages.UnexpectedError] });
    };
    if (isEditMode) {
        return (
            <div>
                <button type="button" className="btn btn-orange me-2" onClick={onSaveClick}>
                    Save
                </button>
                <button type="button" className="btn btn-default" onClick={onCancelClick}>
                    Cancel
                </button>
            </div>
        );
    }

    return (
        <span>
            <ExportFileButton
                url={'/' + ApiRoutes.ExportReport}
                reportType={{
                    id: MyDocumentsTypesEnum.ExpertStrategyDetailExport.Value,
                    availableReportOptions: {
                        customOptions: [
                            ExportOptions.IncludeTactics,
                            ExportOptions.IncludeAttachments,
                            ExportOptions.IncludeStrategyNotes,
                        ],
                    },
                }}
                reportParameters={{
                    reportType: {
                        id: MyDocumentsTypesEnum.ExpertStrategyDetailExport.Value,
                    },
                    options: { key: expertGuid },
                }}
                onError={handleExportError}
                icon="fa-file-pdf"
            />
            <button className="btn btn-no-bg float-end text-gray" onClick={onEditClick}>
                <i className="fal fa-edit" />
                &nbsp;Edit
            </button>
        </span>
    );
}
