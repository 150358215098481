import * as React from 'react';

import { IReportParametersModel, IReportTypeModel } from '../../interfaces/Report/IReport';

import { ExportModal } from './ExportModal';

interface IExportFileProps {
    buttonText?: string;
    cssClasses?: string;
    defaultOpenModal?: boolean;
    onError?: () => void;
    hideIcon?: boolean;
    icon?: string;
    modalTitle?: string;
    reportParameters?: IReportParametersModel;
    reportType: IReportTypeModel;
    submitButtonText?: string;
    url: string;
    validateFn?: () => boolean;
}

export function ExportFileButton(props: IExportFileProps) {
    const [showExportModal, setShowExportModal] = React.useState<boolean>(!!props.defaultOpenModal);

    const handleClick = () => {
        if (typeof props.validateFn === 'function') {
            const isValid = props.validateFn();

            if (isValid) {
                setShowExportModal(true);
            }
        } else {
            setShowExportModal(true);
        }
    };

    return (
        <>
            <button
                className={props.cssClasses ?? 'btn btn-no-bg text-gray float-end'}
                onClick={handleClick}
            >
                {!props.hideIcon && (
                    <>
                        <i className={`fal ${props.icon ? props.icon : 'fa-file-pdf'}`} />
                        &nbsp;
                    </>
                )}
                {props.buttonText ?? 'PDF'}
            </button>

            <ExportModal
                onError={props.onError}
                modalTitle={props.modalTitle}
                onClose={() => setShowExportModal(false)}
                onExportCompleted={() => setShowExportModal(false)}
                open={showExportModal}
                reportParameters={props.reportParameters}
                reportType={props.reportType}
                submitButtonText={props.submitButtonText}
                url={props.url}
            />
        </>
    );
}
